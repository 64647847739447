import {ClickableOptions, DatasetData, LayerPolygonData, SimpleOption} from '@/utils/layers/layout-config'
import {PRETTY_BLUE_STYLE, PRETTY_BLUE_WHITE_STYLE, TRAFFIC_STYLE} from './layerStyles.utils'
import {LayerRole} from '@/auth/roles'
import MapStyleUtils from '@/utils/mapStyle.utils'
import {trafficDensityLayer} from '@/utils/layers/layers-uk'


export const layersDE: DatasetData[] = [
  new LayerPolygonData({
    minZoom: 13,
    name: 'Population density (100m grid, persons per square km)',
    group: 'Demographic data',
    description: 'Population density (2011 Census, 100m grid, persons per square km)',
    dataset: 'Population density (2011 Census, 100m grid, persons per square km)',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Density',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new LayerPolygonData({
    minZoom: 10,
    name: 'Population density (Municipality level, persons per square km)',
    group: 'Demographic data',
    description: 'Population density (2011 Census, 100m grid, persons per square km)',
    dataset: 'Population density (2011 Census, Municipality level, persons per square km)',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Density',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new LayerPolygonData({
    minZoom: 10,
    name: 'Disposable income',
    group: 'Demographic data',
    description: 'Disposable income of private households (EUR)',
    dataset: 'Disposable income of private households (EUR)',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'District',
        property: 'district',
      },
      {
        text: 'Disposable income',
        property: 'value',
        rounded: 2,
      },
    ],
  }),

  new LayerPolygonData({
    role: LayerRole.trafficDensity,
    name: trafficDensityLayer,
    group: 'Traffic data',
    description: 'Display daily average traffic density.',
    minZoom: 13,
    dataset: 'TRAFFIC_DENSITY',
    paintStyle: TRAFFIC_STYLE,
    mapTypeStyle: MapStyleUtils.greyscaleMapStyles,
    tooltips: [
      {
        property: 'w',
        text: 'Whole week daily average',
        rounded: 0,
      },
      {
        property: 'we',
        text: 'Weekend daily average',
        rounded: 0,
      },
      {
        property: 'wd',
        text: 'Weekday daily average',
        rounded: 0,
      },
    ],
    options: new ClickableOptions(
      'Show daily average traffic density for:',
      [
        {
          name: 'Whole week',
          featureProperty: 'w',
        },
        {
          name: 'Weekend',
          featureProperty: 'we',
        },
        {
          name: 'Week days',
          featureProperty: 'wd',
        },
      ],
    ),
  }),
  new LayerPolygonData({
    minZoom: 13,
    name: 'Detached houses ratio',
    group: 'Demographic data',
    description: 'Ratio of detached houses (2011 Census, 100m grid)',
    dataset: 'Detached houses percent',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new LayerPolygonData({
    minZoom: 13,
    name: 'Semi-detached houses ratio',
    group: 'Demographic data',
    description: 'Ratio of semi-detached houses (2011 Census, 100m grid)',
    dataset: 'Semi-detached houses percent',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new LayerPolygonData({
    minZoom: 13,
    name: 'Terraced houses ratio',
    group: 'Demographic data',
    description: 'Ratio of terraced houses (2011 Census, 100m grid)',
    dataset: 'Terraced houses percent',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new LayerPolygonData({
    minZoom: 13,
    name: 'Multi-family buildings ratio',
    group: 'Demographic data',
    description: 'Ratio of multi-family buildings (2011 Census, 100m grid)',
    dataset: 'Multi family buildings percent',
    options: new SimpleOption('value'),
    paintStyle: PRETTY_BLUE_STYLE,
    genericApi: true,
    paintStyleOptions: {
      'roadmap': PRETTY_BLUE_STYLE,
      'hybrid': PRETTY_BLUE_WHITE_STYLE,
    },
    tooltips: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
]
