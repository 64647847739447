import { ChargerCount, ChargerSpeed, Location } from '@/types/app'
import {Option} from '@/components/DSelect.vue'
import {GoogleMapTypes} from '@/models/maps/Map.model'
import {GenericDataset} from '@/models/admin/GenericDataset.model'
import {defineStore} from 'pinia'
import {LocalStorage, LocalStorageKey} from '@/utils/global.utils'
import {getGenericLayerConfiguration, layerConfiguration} from '@/utils/layers/layerMapping.utils'
import {AppRole} from '@/auth/roles'
import {DatasetData} from '@/utils/layers/layout-config'
import {RegionUtils} from '@/utils/region.utils'

function getDefaultMapType() {
  return LocalStorage.getItem(LocalStorageKey.GOOGLE_MAP_TYPE, GoogleMapTypes.hybrid) as GoogleMapTypes
}

export interface ParkingOptions {
  value: number
  label: string
}

export type DisplayOption = 'status' | 'all' | string

export interface UIState {
  selectedFeasibilityItems: string[]
  selectedLayer?: Option<string>
  ownChargers: boolean
  eConnectChargers: boolean
  mapType: GoogleMapTypes
  visibleChargers: ChargerSpeed[]
  genericDatasets: GenericDataset[]
  cpoNames: string[]
  parkingOperators: string[]
  parkingCategories: string[];
  chargerCount?: ChargerCount
  // random has used for firing watchers
  rerenderMapData: any
  mapLastLocation: {bounds: google.maps.LatLngBounds, location: Location} | undefined

  //  if any of the toolbar options are selected
  toolbarData: boolean
  //  if any of the toolbar options are selected
  poiData: boolean
  parkingData: boolean
  layersData: boolean
  feasibilityData: boolean
  catchmentArea: boolean
  landAnalysisData: boolean
  mapLocationName: string
  refresh: boolean
  displayOption: DisplayOption
}

export type UserInterfaceStore = ReturnType<typeof useUserInterfaceStore>

export const useUserInterfaceStore = defineStore('userInterface', {
  state: (): UIState => ({
    selectedFeasibilityItems: [],
    ownChargers: false,
    eConnectChargers: false,
    mapType: getDefaultMapType(),
    visibleChargers: [],
    genericDatasets: [],
    cpoNames: [],
    rerenderMapData: '',
    poiData: false,
    parkingData: false,
    layersData: false,
    toolbarData: false,
    feasibilityData: false,
    landAnalysisData: false,
    mapLastLocation: undefined,
    catchmentArea: false,
    parkingOperators: [],
    parkingCategories: [],
    mapLocationName: '',
    refresh: false,
    displayOption: 'status',
  }),

  getters: {
    cpoNameOptions(): Option[] {
      return this.cpoNames.map(value => ({ value, label: value }))
    },

    layers(): DatasetData[] {
      const layers = layerConfiguration(this.roles).filter((layer) => {
        if (!layer.role) {
          return true
        }

        return this.roles.hasRole(layer.role)
      })

      if (!this.roles.hasRole(AppRole.genericDatasetUpload) || RegionUtils.isUs()) {
        return layers
      }


      const getGenericLayers = this.genericLayers
        .map((dataset: GenericDataset) => getGenericLayerConfiguration(dataset.name))

      return [...layers, ...getGenericLayers]
    },
 
    genericLayers(): GenericDataset[] {
      return this.genericDatasets.filter((dataset: GenericDataset) => dataset.category === 'LAYER')
    },
  },

  actions: {
    resetDisplayOption(): void {
      this.displayOption = 'status'
    },

    isGenericLayer(name: string) {
      return this.genericLayers.some((dataset: GenericDataset) => dataset.name === name)
    },

    getParkingOperatorNameById(id: number): string {
      return this.parkingOperators.find((option) => option.value === id)?.label || ''
    },

    setRerenderMapData(): void {
      this.rerenderMapData = Math.random().toString(36).slice(2, 7)
    },

    setMapType(mapType: GoogleMapTypes): void {
      this.mapType = mapType
      LocalStorage.setItem(LocalStorageKey.GOOGLE_MAP_TYPE, mapType)
    },

    setCpoNames(cpoNames: string[]): void {
      this.cpoNames = cpoNames.toSorted((a, b) => a.localeCompare(b))
    },

    setParkingOperators(parkingOperators: string[]): void {
      this.parkingOperators = parkingOperators.sort()
    },

    setParkingCategories(parkingCategories: string[]): void {
      this.parkingCategories = parkingCategories.sort()
    },

    changeMapType(): void {
      if (this.mapType === GoogleMapTypes.hybrid) {
        this.setMapType(GoogleMapTypes.roadmap)
      } else {
        this.setMapType(GoogleMapTypes.hybrid)
      }
    },

    async loadRegionChargerCount(stateId?: number): Promise<void> {
      const handleError = (e: Error) => {
        console.error('error loading charger count', e)

        return {
          categorization: {
            level_2: 0,
            level_3_dc_fast: 0,
            slow: 0,
            fast: 0,
            rapid: 0,
            ultra_rapid: 0,
          },
        }
      }

      if (!stateId) {
        this.chargerCount = handleError(new Error('stateId is undefined')).categorization
        return
      }

      this.chargerCount = await this.api.getChargerDetails(stateId).catch(handleError).then(data => data.categorization)
    },
  },
})
